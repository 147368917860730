<template>
    <div class="row" data-padding="smtop">
        <div class="col-12" v-if="countries !== null && countries.length > 0">
            <div data-aos="fade-in" class="row">
                <div class="col-12">
                    <div class="sectiontitle-element">
                        <h3>
                            <!-- Available in {{ countries.length }} countries as of today, with many more coming soon. -->

                            Direct payments into Bank Accounts/Momo Wallets are
                            available in these [{{ countries.length }}]
                            countries. Others will be added soon.
                        </h3>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <carousel
                        :items="2"
                        :autoplay="true"
                        :nav="false"
                        :dots="true"
                        :margin="45"
                        :stagePadding="10"
                        :autoHeight="true"
                        :responsive="{
                            540: { items: 3 },
                            768: {
                                items: 5,
                                dots: true,
                                stagePadding: 0,
                            },
                            1200: {
                                items: 8,
                                dots: false,
                                stagePadding: 0,
                            },
                        }"
                        class="country-carousel"
                    >
                        <div
                            v-for="(country, index) in countries"
                            :key="index"
                            class="logo-element"
                        >
                            <!-- :src="country.imageUrl" -->
                            <img
                                :src="country.logo"
                                :alt="`${country.name} logo`"
                                @mouseover="imageHovered"
                            />
                            <h4
                                class="country-back"
                                @mouseout="textNotHovered"
                                @click="imageHovered"
                                :aria-label="country.name"
                            >
                                {{ country.name }}
                            </h4>
                        </div>
                    </carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import $ from "jquery";
import { mapActions, mapState } from "vuex";

export default {
    components: { carousel },

    computed: {
        ...mapState(["countries"]),
    },

    data() {
        return {
            // allCountries: [
            //     {
            //         name: "Nigeria",
            //         imageUrl: require("@/assets/img/countries/icon-nigeria.svg"),
            //     },
            //     {
            //         name: "Ghana",
            //         imageUrl: require("@/assets/img/countries/icon-ghana.svg"),
            //     },
            //     {
            //         name: "Rwanda",
            //         imageUrl: require("@/assets/img/countries/icon-rwanda.svg"),
            //     },
            // ],
        };
    },

    methods: {
        ...mapActions(["getReceiveCountries"]),

        imageHovered(e) {
            $(".logo-element img").css("opacity", "0.4");
            e.target.style.opacity = "1";
            e.target.nextSibling.style.display = "flex";
        },

        // textHovered(e) {
        //     // console.log(e.target);
        //     // e.stopPropagation();

        //     $(".logo-element .country-back").css("display", "none");
        //     e.target.style.display = "flex";
        // },

        textNotHovered() {
            $(".logo-element img").css("opacity", "1");
            $(".logo-element .country-back").css("display", "none");
        },
    },

    mounted() {
        this.getReceiveCountries();
    },
};
</script>

<style lang="scss" scoped>
.sectiontitle-element h3 {
    max-width: 700px;
    font-size: 1.125rem;
    font-weight: normal;
    color: var(--white);
    line-height: 1.6;
}

.country-carousel {
    margin-top: 0.5rem;
}

[class|="logo-element"] {
    position: relative;
    height: 60px;
    width: 100px;

    .country-back {
        font-size: 18px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        text-align: center;
        line-height: 22px;
        background-color: var(--orangeLight);
        border-radius: 10px;
    }

    img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
    }

    .country-back,
    img {
        -o-transition: 0.3s ease;
        -moz-transition: 0.3s ease;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
    }
}
</style>
